import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown, Form, Button } from "semantic-ui-react";
// import DateFilter from './DateFilter.js';
import { useState } from "react";
import { saveSearchResults, addfilter} from "../../redux/actions";


function Filter(props) {
  console.log(JSON.parse(props.newFilterValues));
  const [appliedFilter, setAppliedFilter] = useState(
    JSON.parse(props.newFilter)
  );
  const [type, setType] = useState("");
  const optiondata = [];
  if(props.newFilterValues){
    let tempdata = JSON.parse(props.newFilterValues);
    tempdata.map((fieldvalue)=>{
        optiondata.push({ key: fieldvalue, value: fieldvalue, text: fieldvalue})
      })  
           
  }

  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    console.log(!open);
    setOpen(!open);
  };

  const saveFilter = (type, value) => {
    type === "type" && setType(value);
    setAppliedFilter(value);
  };

  const handleFilter = () => {
    props.applyfilter(appliedFilter);
    props.handleClose();
  };
  return (
    <>
      <div className="filter-popup">
        {/* filter colums for selection knowledge source and equipments with dropdown */}
        <Form className="filter-form">
          <div className="series-wrapper">
            <div
              className="close-icon"
              onClick={() => {
                props.handleClose();
              }}
            >
              {" "}
              X
            </div>
            <Form.Field>
              <label>
                {" "}
                Type
                :{" "}
              </label>
              <Dropdown
                placeholder={"Select Type"}
                fluid
                selection
                upward={false}
                options={optiondata}
                value={appliedFilter}
                onChange={(e, data) => {
                  if(appliedFilter !== null && (appliedFilter.length > data.value.length)){
                    saveFilter("type", data.value);
                  }
                  else{
                    saveFilter("type", data.value);
                    toggleDropdown();
                  }
                  
                }}
                clearable
                multiple
                search={true}
                noResultsMessage={null}
                open={open}
                onOpen={toggleDropdown}
                onClose={toggleDropdown}
                className="filer-dropdown"
              />
            </Form.Field>
          </div>

          <div className="button-wrapper">
            <Button
              onClick={(e) => {
                setAppliedFilter([]);
                props.applyfilter(null);
                props.handleClose();
              }}
            >
              Clear Filter
            </Button>
            <Button
              primary
              onClick={(e) => {
                handleFilter();
              }}
            >
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isTroubleshooting: state.isTroubleshooting,
    newFilter: state.newFilter,
    newFilterValues: state.newFilterValues,
    displaytextcontent: state.displaytextcontent,
    languageselected: state.languageselected,
  };
};

export default connect(mapStateToProps, {
  saveSearchResults,
  addfilter,
})(Filter);