import * as React from "react";
import { Icon, Dropdown } from "semantic-ui-react";

const spellcheckAPI = process.env.REACT_APP_SPELLCHECK_URL; // Spellcheck API endpoint

/* This component is for the search bar with autocomplete and spellcheck */
class LexxSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "", // search query string
      suggestions: [], // autocomplete suggestions
      showSuggestions: false, // control suggestions dropdown visibility
      wsConnection: null, // WebSocket connection
      spellcheckInfo: null, // Store spellcheck response
      usedCorrectedSpelling: false, // Track if we used corrected spelling
      originalQuery: "", // Store original query for "search instead" option
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchClick = this.searchClick.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.checkSpelling = this.checkSpelling.bind(this);
    this.searchWithOriginal = this.searchWithOriginal.bind(this);
    this.searchInputRef = React.createRef();
    this.suggestionTimeoutRef = null;
  }

  componentDidMount() {
    // Initialize WebSocket connection
    this.initWebSocket();
    
    // Add click outside listener
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // Clean up WebSocket connection
    if (this.state.wsConnection) {
      this.state.wsConnection.close();
    }
    
    // Clear any pending timeouts
    if (this.suggestionTimeoutRef) {
      clearTimeout(this.suggestionTimeoutRef);
    }
    
    // Remove event listener
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  initWebSocket() {
    const ws = new WebSocket(process.env.REACT_APP_AUTOCOMPLETE_URL);
    
    ws.onopen = () => {
      console.log("WebSocket connection established");
      this.setState({ wsConnection: ws });
    };
    
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        // Format suggestions from the response
        const formattedSuggestions = data.map(([text, score]) => ({
          key: text,
          text: text,
          value: text,
          score: score
        }));
        this.setState({
          suggestions: formattedSuggestions,
          showSuggestions: formattedSuggestions.length > 0
        });
      } catch (error) {
        console.error("Error parsing WebSocket response:", error);
        this.setState({
          suggestions: [],
          
          showSuggestions: false
        });
      }
    };
    
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    
    ws.onclose = () => {
      console.log("WebSocket connection closed");
      this.setState({ wsConnection: null });
    };
  }

  handleClickOutside(e) {
    if (this.searchInputRef.current && !this.searchInputRef.current.contains(e.target)) {
      this.setState({ showSuggestions: false });
    }
  }
  
  /**
   * Call the spellcheck API to check and correct spelling
   */
  async checkSpelling(text) {
    try {
      const params = new URLSearchParams({
        text: text,
        model_name: `edp_prod`,
      });
      const response = await fetch(spellcheckAPI+`?`+params.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        return {"correction":{"original_text":text,"spell_corrected_text":text,"correction_dict":{}}}
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error checking spelling:", error);
      return {"correction":{"original_text":text,"spell_corrected_text":text,"correction_dict":{}}}
    }
  }

  /**
   * Search with the original uncorrected query
   */
  searchWithOriginal(e) {
    if (e) e.preventDefault();
    this.setState({ 
      searchString: this.state.originalQuery,
      usedCorrectedSpelling: false,
      spellcheckInfo: null
    }, () => {
      this.props.act(this.state.searchString);
    });
  }

  /* Function called 
      When enter key is pressed, search the solr 
  */
  async handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState({ showSuggestions: false });
      
      // Check spelling before searching
      const spellcheckResult = await this.checkSpelling(this.state.searchString);
      
      if (spellcheckResult && 
          spellcheckResult.correction && 
          spellcheckResult.correction.spell_corrected_text !== spellcheckResult.correction.original_text) {
        
        // Store the original query
        const originalQuery = this.state.searchString;
        
        // Update the search string with corrected text
        this.setState({
          spellcheckInfo: spellcheckResult.correction,
          usedCorrectedSpelling: true,
          originalQuery: originalQuery,
          searchString: spellcheckResult.correction.spell_corrected_text
        }, () => {
          // Search with corrected text
          this.props.act(this.state.searchString, spellcheckResult.correction);
        });
      } else {
        // No correction needed, search with current text
        this.setState({
          usedCorrectedSpelling: false,
          spellcheckInfo: null
        });
        this.props.act(this.state.searchString, spellcheckResult && spellcheckResult.correction);
      }
    }
  }

  /* Update the search String when user types and request suggestions */
  handleChange(event) {
    const value = event.target.value;
    
    this.setState({
      searchString: value
    });
    
    // Clear any existing timeout
    if (this.suggestionTimeoutRef) {
      clearTimeout(this.suggestionTimeoutRef);
    }
    
    // Request suggestions even for one letter (changed from > 2 to > 0)
    if (value.length > 0 && this.state.wsConnection && this.state.wsConnection.readyState === WebSocket.OPEN) {
      this.suggestionTimeoutRef = setTimeout(() => {
        this.state.wsConnection.send(value);
      }, 300);
    } else {
      this.setState({
        suggestions: [],
        showSuggestions: false
      });
    }
  }

  /* Handle selection of a suggestion */
  async handleSuggestionSelect(e, { value }) {
    this.setState({
      searchString: value,
      showSuggestions: false
    });

    // Check spelling before searching
    const spellcheckResult = await this.checkSpelling(value);
    
    if (spellcheckResult && 
        spellcheckResult.correction && 
        spellcheckResult.correction.spell_corrected_text !== spellcheckResult.correction.original_text) {
      
      // Store the original query
      const originalQuery = value;
      
      // Update the search string with corrected text
      this.setState({
        spellcheckInfo: spellcheckResult.correction,
        usedCorrectedSpelling: true,
        originalQuery: originalQuery,
        searchString: spellcheckResult.correction.spell_corrected_text
      }, () => {
        // Search with corrected text
        this.props.act(this.state.searchString, spellcheckResult.correction);
      });
    } else {
      // No correction needed, search with current text
      this.setState({
        usedCorrectedSpelling: false,
        spellcheckInfo: null
      });
      this.props.act(value, spellcheckResult && spellcheckResult.correction);
    }
  }

  /* Function called 
      When btn is clicked, search the solr 
  */
  async searchClick() {
    this.setState({ showSuggestions: false });
    
    // Check spelling before searching
    const spellcheckResult = await this.checkSpelling(this.state.searchString);
    
    if (spellcheckResult && 
        spellcheckResult.correction && 
        spellcheckResult.correction.spell_corrected_text !== spellcheckResult.correction.original_text) {
      
      // Store the original query
      const originalQuery = this.state.searchString;
      
      // Update the search string with corrected text
      this.setState({
        spellcheckInfo: spellcheckResult.correction,
        usedCorrectedSpelling: true,
        originalQuery: originalQuery,
        searchString: spellcheckResult.correction.spell_corrected_text
      }, () => {
        // Search with corrected text
        this.props.act(this.state.searchString, spellcheckResult.correction);
      });
    } else {
      // No correction needed, search with current text
      this.setState({
        usedCorrectedSpelling: false,
        spellcheckInfo: null
      });
      this.props.act(this.state.searchString, spellcheckResult && spellcheckResult.correction);
    }
  }

  render() {
    const { 
      suggestions, searchString, showSuggestions,
      spellcheckInfo, usedCorrectedSpelling, originalQuery 
    } = this.state;

    // Transform suggestions to bold the matching part
    const highlightedSuggestions = suggestions.map(s => {
      const lowerText = s.text.toLowerCase();
      const lowerQuery = searchString.toLowerCase();
      const matchIndex = lowerText.indexOf(lowerQuery);
      if (matchIndex !== -1 && searchString.length > 0) {
        const before = s.text.slice(0, matchIndex);
        const match = s.text.slice(matchIndex, matchIndex + searchString.length);
        const after = s.text.slice(matchIndex + searchString.length);
        return {
          ...s,
          // Use the content property to display custom markup.
          content: (
            <span>
              {before}
              <b>{match}</b>
              {after}
            </span>
          )
        };
      }
      return s;
    });

    // Prepare the spellcheck display element
    let spellcheckElement = null;
    if (usedCorrectedSpelling && spellcheckInfo) {
      // Create highlighted text with corrected words in bold
      const correctionDict = spellcheckInfo.correction_dict || {};
      const correctedWords = searchString.split(' ');
      
      // Build the "Showing results for" message with styled corrected words
      const showingResultsWords = correctedWords.map((word, index) => {
        // Check if this word was corrected by looking in correction_dict
        const isChanged = Object.values(correctionDict).includes(word);
        return isChanged ? 
          <b key={index} style={{color: '#1a73e8'}}>{word} </b> : 
          <span key={index} style={{color: '#1a73e8'}}>{word} </span>;
      });

      spellcheckElement = (
        <div className="spellcheck-suggestion">
          <div>
            Showing results for {showingResultsWords}
          </div>
          <div>
            <a href="#" onClick={this.searchWithOriginal} style={{color: '#1a73e8'}}>
              Search instead for {originalQuery}
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className="lexx-search-container" ref={this.searchInputRef}>
        <div className="search-input-wrapper">
          <input
            className="search-input"
            onChange={(e) => this.handleChange(e)}
            onKeyPress={(e) => this.handleKeyPress(e)}
            value={searchString}
            placeholder="Search"
          />
          <Icon 
            name="arrow right" 
            onClick={() => this.searchClick()} 
          />
        </div>
        
        {spellcheckElement}
        
        {showSuggestions && highlightedSuggestions.length > 0 && (
          <div className="lexx-suggestions-dropdown">
            <Dropdown
              open={true}
              fluid
              selection
              options={highlightedSuggestions}
              value=""
              onChange={this.handleSuggestionSelect}
              className="suggestion-dropdown"
            />
          </div>
        )}
      </div>
    );
  }
}

export default LexxSearchBar;