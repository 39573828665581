import React from 'react';
import { Image } from 'semantic-ui-react';
import TechnicianIcon from '../../assets/images/technician.png';
import { useHistory } from 'react-router-dom';
 import { connect } from "react-redux";
 import {
     saveSearchQuery,
 
   } from "../../redux/actions";


/* This component is used where the design showcases technician image
    along with some message for eg : well done ! 
*/
const LexxIconHeading = (props) => {
    const history = useHistory();
     
     function handleSearchInstead(){
         props.saveSearchQuery(props.spellcheck.original_text);
         //reload the page with the new search query
 
         history.push(`/troubleshooting?search_instead=${props.spellcheck.original_text}`);
     }

    return (
        <>
            <div className="icon-text-wrapper">
                <Image src={TechnicianIcon} className="tech-icon" />
                { !(props.spellcheck && props.spellcheck.original_text && props.spellcheck.spell_corrected_text && props.spellcheck.original_text !== props.spellcheck.spell_corrected_text)?
                     <p className='page-header-text'>
                         {props.text}
                     </p>:<p className='page-header-text'>
                     {`We found some results for "${props.spellcheck.spell_corrected_text}" instead of "${props.spellcheck.original_text}". Search instead for `}
                     <a className="spellcheck-link" style ={{textDecoration:"underline", textDecorationColor:"#4183c4", cursor:"pointer"}} onClick={handleSearchInstead}>{props.spellcheck.original_text}</a>
                     </p>
                 }
                
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
      searchResults: state.searchResults,
      troubleshootingData: state.troubleshootingData,
      isTroubleshooting: state.isTroubleshooting,
      spellcheck: state.spellcheck,
      userInfo: state.userInfo,
      searchQuery: state.searchQuery,
      isSearchClicked: state.isSearchClicked,
      numFound: state.numFound,
      newFilter : state.newFilter,
      activePage: state.activePage,
    };
  };

  export default connect(mapStateToProps, {
    saveSearchQuery,
  
})(LexxIconHeading);