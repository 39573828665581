// In BannerPop.jsx
import React from "react";

const Banner = ({ showBanner, setShowBanner }) => {
  if (!showBanner) {
    return null;
  }

  const handleClose = () => {
    setShowBanner(false);
  };

  return (
    <div style={{ 
      backgroundColor: '#5CAAF0', 
      padding: '12px', 
      textAlign: 'center', 
      position: 'relative', 
      width: '100%', 
      boxSizing: 'border-box',
      zIndex: 1000,
      gap: '5px'
    }}>
      <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}>
        <p style={{
          fontSize: '0.875rem', 
          color: 'white', 
          marginBottom: '0.5rem', 
          textAlign: 'center'
        }}>
          🚀 xAssist Just Got Smarter!
Now with AI Autocomplete & Spell Check to boost your speed and accuracy. Try it out today!
        </p>
        <button
          style={{
            position: 'absolute', 
            top: '0.25rem', 
            right: '0.5rem', 
            backgroundColor: 'transparent', 
            border: 'none', 
            color: 'white', 
            cursor: 'pointer', 
            fontSize: '1.25rem'
          }}
          onClick={handleClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Banner;
